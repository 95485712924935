import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

const Pages = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityPage(
        filter: { client: { eq: "rml" }, slug: { current: { ne: "null" } } }
        sort: { fields: _createdAt, order: DESC }
      ) {
        edges {
          node {
            title
            slug {
              current
            }
            _createdAt
          }
        }
      }
    }
  `)

  const lp = data.allSanityPage.edges || []

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <ul>
      {lp
        .filter((page) => page.node.slug !== null)
        .map((page, index) => {
          const timestamp = page.node._createdAt
          const date = new Date(timestamp)
          const day = date.getDate()
          const month = monthNames[date.getMonth()]
          const year = date.getFullYear()

          return (
            <li key={index}>
              {day} {month} {year}&nbsp;&nbsp;
              <Link to={`/${page.node.slug.current}`}>{page.node.title}</Link>
            </li>
          )
        })}
    </ul>
  )
}

export default Pages
