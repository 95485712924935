import React from 'react'
import styled from 'styled-components'
import Pages from '../../components/Pages'
import Layout from '../../components/Layout'

const Container = styled.div`
  display: flex;
  min-height: 440px;
  flex-direction: column;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  h2 {
    margin-bottom: 1rem;
  }
  li {
    font-size: 16px;
    list-style: none;
  }
`

const PagesIndex = () => {
  return (
    <Layout>
      <Container>
        <div>
          <Pages />
        </div>
      </Container>
    </Layout>
  )
}

export default PagesIndex
